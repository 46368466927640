import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../../components/Layout';
import '../../../styles/media.css';
import imageCondo from '../../../images/legal/condo.jpg';
import imageLease from '../../../images/legal/lease-area.jpg';
import imageRightOfWay from '../../../images/legal/right-of-way-v2.jpg';
import imageSubdivision from '../../../images/legal/subdivision.jpg';
import imageRpr from '../../../images/residential/rpr.jpg';

const LegalIndex = () => (
  <Layout
    title="Legal Surveys | Axiom Geomatics | Calgary, Red Deer"
    description="Only certified Alberta Land Surveyors can provide legal land surveys registered at the Land Titles Office for new subdivisions and more."
    keywords="land titles, land titles plans, registering a plan, property ownership, register/create subdivisions/condos (break this into pieces), create a lot, alberta land surveyor"
  >
    <main>
      <h1>Legal Surveys</h1>
      <p>
        Legal surveys include all work related with ownership and transference of property rights.
        Axiom Geomatics offers the following to our clients requiring property ownership surveys:
      </p>

      <div className="media-list">
        <Link to="/services/residential-surveys/real-property-reports">
          <img
            src={imageRpr}
            alt="Real Property Report sample"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/residential-surveys/real-property-reports">
            Real Property Reports
          </Link>
        </h2>
        <p>
          A Real Property Report (RPR) is a legal document showing information about a property
          including dimensions, permanent features, easements, right-of-ways and encroachments
          relative to property lines. In Alberta it is often required when real estate is sold.
        </p>

        <p className="align-right">
          <Link to="/services/residential-surveys/real-property-reports">
            Learn about Real Property Reports
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/legal-surveys/subdivision-surveys">
          <img
            src={imageSubdivision}
            alt="Subdivision"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/legal-surveys/subdivision-surveys">Subdivision Surveys</Link>
        </h2>
        <p>
          Subdivision is taking a single parcel of land and dividing it into two or more parcels,
          each with their own land title. This work ranges from subdividing a single plot into two
          infills to creating entirely new neighbourhoods.
        </p>

        <p className="align-right">
          <Link to="/services/legal-surveys/subdivision-surveys">
            Learn about Subdivision Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/legal-surveys/right-of-way-surveys">
          <img
            src={imageRightOfWay}
            alt="Right-of-ways"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/legal-surveys/right-of-way-surveys">Right-of-Way Surveys</Link>
        </h2>
        <p>
          Utility, overland drainage and maintenance access right-of-ways may have rights over your
          property that restrict what you are able to do within your land.
        </p>

        <p className="align-right">
          <Link to="/services/legal-surveys/right-of-way-surveys">
            Learn about Right-of-Way Surveys
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/legal-surveys/condominium-services">
          <img
            src={imageCondo}
            alt="Condominium"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/legal-surveys/condominium-services">Condominium Services</Link>
        </h2>
        <p>
          A condominium is a type of ownership, not a building. Condominium services include
          creation of a new condominium.
        </p>

        <p className="align-right">
          <Link to="/services/legal-surveys/condominium-services">
            Learn about Condominium Services
          </Link>
        </p>
      </div>

      <div className="media-list">
        <Link to="/services/legal-surveys/lease-area-surveys">
          <img
            src={imageLease}
            alt="Lease Area survey"
            className="img img-left "
            width="200"
            height="200"
          />
        </Link>
        <h2>
          <Link to="/services/legal-surveys/lease-area-surveys">Lease Area Surveys</Link>
        </h2>
        <p>
          Lease areas may allow a party, other than the owner of the property, to have limited
          rights to a property. The BOMA standards are the most popular standards for Lease Area
          Surveys.
        </p>

        <p className="align-right">
          <Link to="/services/legal-surveys/lease-area-surveys">
            Learn about Lease Area Surveys
          </Link>
        </p>
      </div>
    </main>
  </Layout>
);

export default LegalIndex;
